
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closePopUp() {
      this.active = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.active = true;
    }, 3000);
  },
});
